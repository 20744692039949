
<template>
    <div class="container">
        <!-- <div class="row justify-content-center">
            <div class="col-8 search-bar p-3 px-4">
              <h3>O que você procura?</h3>
                <form id="simple" name="searchForm" method="get" target="_self" action="https:///uninove.primo.exlibrisgroup.com/discovery/search" enctype="application/x-www-form-urlencoded; charset=utf-8" onsubmit="searchPrimo()">
                    <div class="mb-3 input-group">
                        Fill in Highlighted Parameters
                        <input type="hidden" name="vid" value="55UNINOVE_INST:UNINOVE">
                        <input type="hidden" name="tab" value="Everything">
                        <input type="hidden" name="search_scope" value="MyInst_and_CI">
                        <input type="hidden" name="lang" value="pt">
                        Fixed parameters
                        <input type="hidden" name="query" id="primoQuery">
                        <input type="text" id="primoQueryTemp" class="form-control" value="" size="35">
                        <select class="form-select" id="inputGroupSelect01">
                            <option selected>Livro Digital</option>
                            <option value="1">Livro Físico</option>
                            <option value="2">Artigos</option>
                            <option value="3">Coleções Eletrônicas</option>
                            <option value="3">Monografias</option>
                            <option value="3">Periódicos</option>
                            <option value="3">Editora UNINOVE</option>
                            <option value="3">CD/DVD</option>
                        </select>
                        <button  class="btn btn-outline-secondary" id="go" title="Search" v-on:click="searchPrimo" type="button" value="Search" alt="Search">Pesquisar</button>
                    </div>
                    <div>
                        <div class="col-12" style="text-align: center">
                            <a href="#">Pesquisa Avançada -></a>
                        </div>
                    </div>
                </form>
            </div>
      </div>-->
      
        <!-- <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Medicina" sub_titulo="1.200 livros encontrados" categoria="medicina"></slidejs>
        <slidejs formato="categoria" iconeCategoria="law-colour.svg" titulo="Direito" sub_titulo="1.200 livros encontrados" categoria="direito"></slidejs>
        <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Engenharia Civil" sub_titulo="1.200 livros encontrados" categoria="engenharia-civil"></slidejs>
        <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Odontologia" sub_titulo="1.200 livros encontrados" categoria="odontologia"></slidejs>
        <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Veterinária" sub_titulo="1.200 livros encontrados" categoria="medicina-veterinaria"></slidejs>
        <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Ciência da Computacao" sub_titulo="1.200 livros encontrados" categoria="ciencia-da-computacao"></slidejs>
        <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Pedagogia" sub_titulo="1.200 livros encontrados" categoria="pedagogia"></slidejs>
        <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Ciências Contábeis" sub_titulo="1.200 livros encontrados" categoria="ciencias-contabeis"></slidejs> -->

        <h1>Painel de Controle</h1>
        <h3>Todas as Rotas:</h3>
        <div v-for="item in items" :key="item.id">
            <router-link :to="item.path">
                {{item.name}}
            </router-link>
        </div>
    </div>
</template>

<script>

  export default {
      components: {
      },
      created() {
          console.log(this.$router.options.routes)
      this.$router.options.routes.forEach(route => {
          this.items.push({
              name: route.name
              , path: route.path
          })
      })
    },
    data() {
        return {
            items: []
        }
    },
  }
</script>

<style scoped>
    .cover-book{
        max-height: 150px !important;
    }
</style>